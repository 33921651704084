import React, { Component } from "react";
import PropTypes from "prop-types";
import { localStorageRemoveItem, localStorageGetItem ,localStorageSetItem} from '../../persistence/localStorage';
import AudioSwitch from "./AudioSwitch";
import VideoPlayerFactory from "../../adapters/helpers/VideoPlayerFactory";
import { VideoPlayerConstants } from "../../adapters/helpers/Constants";
import { Cloudinary } from "cloudinary-core";


class VideoPlayerAudioDescription extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.videoPlayer = null;
    this.video = props.video;
    this.state = {
      video: null,
    };
  }  

  componentDidMount() {
    require('cloudinary-video-player');    
    if (this.videoIsDefined()) {
      const videoPlayerFactory = new VideoPlayerFactory(
        this.videoRef,
        this.video
      );
      this.createVideoPlayer(videoPlayerFactory);    
    }    
    const cld = new Cloudinary({ cloud_name: "OralB" });

    const videoPlayer = cld.videoPlayer("player", {
        muted: false,
        controls: true
    });    
    let videoFile = videoPlayer.videojs    
    let ratioVolume = VideoPlayerConstants.videoRatioVolume;    
    let firstAudiofile = document.getElementById('firstAudiofile');	
		let secondAudiofile = document.getElementById('secondaudio');
    let audioDescriptionIcon = document.getElementsByClassName('vjs-icon-audio-description')
    localStorageRemoveItem("firstAudio");
		localStorageRemoveItem("secondAudio");
    if ( firstAudiofile ) {
        let ADButton = videoPlayer?.videojs?.controlBar?.addChild('button')
        ADButton.el_.classList.add('vjs-icon-audio-description');
        if ( audioDescriptionIcon && audioDescriptionIcon.length > 0 ) {
          audioDescriptionIcon[0].style.opacity = VideoPlayerConstants.descriptionButtonLowOpacity
        }
        firstAudiofile.volume = ratioVolume;        
        ADButton.on('click', function( e ) {	
        if ( audioDescriptionIcon && audioDescriptionIcon.length > 0 ) {
          audioDescriptionIcon[0].style.opacity = VideoPlayerConstants.descriptionButtonHighOpacity
        }                                         
            if( firstAudiofile.volume ){							
                firstAudiofile.pause();
                firstAudiofile.volume = 0;
                secondAudiofile.play();
            } else {
                firstAudiofile.volume = Math.min( videoFile.volume() + ratioVolume , 1)
                firstAudiofile.play();
                secondAudiofile.pause();
                if ( audioDescriptionIcon && audioDescriptionIcon.length > 0 ) {
                  audioDescriptionIcon[0].style.opacity = VideoPlayerConstants.descriptionButtonLowOpacity
                }
            }
        });
        videoFile.on('play', function() {
          localStorageGetItem("firstAudio") == "pause" ? firstAudiofile.play() : localStorageGetItem("secondAudio") == "pause" ? secondAudiofile.play() : firstAudiofile.play();            
        });

      videoFile.on('pause', function () {
        if (!firstAudiofile.paused) {
          firstAudiofile.pause();
          localStorageSetItem("firstAudio", "pause");
          localStorageRemoveItem("secondAudio");
        }
        else if (!secondAudiofile.paused) {
          secondAudiofile.pause();
          localStorageRemoveItem("firstAudio");
          localStorageSetItem("secondAudio", "pause");
        } else {
          firstAudiofile.pause();
          secondAudiofile.pause();
        }
      });

      videoFile.on('ended', function () {
        videoFile.pause();
        firstAudiofile.pause();
      });

      videoFile.on('volumechange', function () {
        firstAudiofile.volume = videoFile.volume();
        secondAudiofile.volume = videoFile.volume();
        if (videoFile.muted()) {
          firstAudiofile.muted = true;
          secondAudiofile.muted = true;
        } else {
          firstAudiofile.muted = false;
          secondAudiofile.muted = false;
        }
      });
              
      videoFile.on('timeupdate', function () {
        if (!firstAudiofile.paused && firstAudiofile.readyState >= 4) {
          if (Math.ceil(firstAudiofile.currentTime) != Math.ceil(this.currentTime())) {
            firstAudiofile.currentTime = this.currentTime();
          }
        }
        if (!secondAudiofile.paused && secondAudiofile.readyState >= 4) {
          if (Math.ceil(secondAudiofile.currentTime) != Math.ceil(this.currentTime())) {
            secondAudiofile.currentTime = this.currentTime();
          }
        }
      });
    }
  }  

  createVideoPlayer(videoPlayerFactory) {
    const subTitles = this.props.video?.subTitles?.map((subtitle) => ({
      label: subtitle?.fields?.label,
      language: subtitle?.fields?.language,
      url: subtitle?.fields?.url,
    }));
    let textTracks = {
      captions: {
        label: this.props.video?.captions?.fields?.label,
        default: this.props.video?.captions?.fields?.default,
        language: this.props.video?.captions?.fields?.language,
        url: this.props.video?.captions?.fields?.url,
      },
      subtitles: subTitles && [...subTitles],
    };
    textTracks = subTitles && this.props.video?.captions && textTracks;
    const source = textTracks
      ? { sourceTypes: ["webm", "mp4", "hls", "ogg"], textTracks: textTracks }
      : { sourceTypes: ["webm", "mp4", "hls", "ogg"] };
    videoPlayerFactory
      .create()
      .then(({ videoPlayer, transformation }) => {          
        const video = videoPlayer.Cloudinary.new({ secure: true })
          .videoPlayer(this.videoRef.current, {
            loop: this.video.loop,
            fontFace: "inherit",
            fluid: true,
            preload: this.video.preload ? true : false,
            controls: this.video.controls,
            autoplayMode: this.video.autoplay,
            playsinline: this.video.playsInline,
          })
          .transformation(transformation)
          .source(this.video?.asset?.fields?.file?.url, source);
        this.setState({
          video: video,
        });        
        this.videoPlayer = video;
        this.videoPlayer.on("canplay", this.onVideoLoaded.bind(this));
        this.videoPlayer.on("play", this.onVideoPlay.bind(this));
        this.videoPlayer.on("pause", this.onVideoPause.bind(this));
        this.videoPlayer.on("mute", this.onVideoMuted.bind(this));
        this.videoPlayer.on("unmute", this.onVideoUnMuted.bind(this));
        this.videoPlayer.on(
          "fullscreenchange",
          this.onVideoFullScreen.bind(this)
        );
      })
      .catch((error) => { console.error(error) });
  }

  videoIsDefined() {
    return this.video && this.video?.asset?.fields?.file?.url;
  }

  renderTranscript() {
    return (
      <div className="video-player-transcript visuallyhidden">
        {this.video?.transcript}
      </div>
    );
  }


  render() {
    const captionsTrack = this.props.video?.captions?.fields;
    const subtitlesVideo= this.props.video?.subTitles;
    const subTitleTrack = subtitlesVideo?.[0]?.fields || null;
    const name = this.props.video?.keyframe?.fields?.asset?.fields?.title;
    const description = this.props.video?.keyframe?.fields?.asset?.fields
      ?.description;
    const thumbnailurl = this.props.video?.keyframe?.fields?.asset?.fields?.file
      ?.url;
    const UploadDate = this.props.video?.uploadDate;
    const duration = this.props.video?.duration;
    const width = this.props.video?.videoSettings?.fields?.width;
    const height = this.props.video?.videoSettings?.fields?.height;
    const contenturl = this.props.video?.asset?.fields?.file?.url;
    const ariaLabel = this.props.video?.ariaLabel;
    
    return (
      <div className="ob-video-player videoAudioDescription">
        <video
          muted
          playsInline
          ref={this.videoRef}
          id="player"          
          aria-label={ariaLabel}
          crossOrigin="anonymous"
        >          
          <source src={'https:' + this.video?.asset?.fields?.file?.url} type="video/mp4" />
		      {captionsTrack && <track label={captionsTrack?.label} kind="captions" srcLang={captionsTrack?.language} src={captionsTrack?.url} default={captionsTrack?.default}></track>}
          {subTitleTrack && <track label={subTitleTrack?.label} kind="subtitles" srcLang={subTitleTrack?.language} src={subTitleTrack?.url} default={subTitleTrack?.default}></track>}
        </video>

        <AudioSwitch idValue="firstAudiofile" audioSrc={this.video?.videoAudio?.fields?.file?.url}/>

        <AudioSwitch idValue="secondaudio" audioSrc={this.video?.videoAudioDescription?.fields?.file?.url}/>
       
        {this.renderTranscript()}
      </div>
    );
  }
}

VideoPlayerAudioDescription.propTypes = {
  video: PropTypes.object,
  onVideoPlaying: PropTypes.func,
};

export default VideoPlayerAudioDescription;