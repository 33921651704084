import React from 'react';
import PropTypes, { any } from 'prop-types';
import {VideoPlayerConstants} from '../../adapters/helpers/Constants';

export default function AudioSwitch({idValue, audioSrc}) {
    return(
        <audio id={idValue} preload='auto'>
          <source src={VideoPlayerConstants.domainHttp + audioSrc} type="audio/mp3" />
        </audio>
    )
}

AudioSwitch.propTypes = {
  idValue: PropTypes.string,
  audioSrc: PropTypes.string | any
}